import { MessageKey } from "lang";

export const validateEmail = (
  email: string,
  setError: (error: MessageKey | undefined) => void,
): boolean => {
  if (!email) {
    setError("error.email.required");
    return false;
  }
  setError(undefined);
  return true;
};

export const validatePassword = (
  password: string,
  setError: (error: MessageKey | undefined) => void,
) => {
  if (!password) {
    setError("error.password.required");
    return false;
  }
  setError(undefined);
  return true;
};
