import { Grid2, Typography } from "@mui/material";
import { useEffect, useMemo, useRef } from "react";
import { Pie, PieChart, ResponsiveContainer } from "recharts";

import { Widget, WidgetContent } from "components/elements/mui";
import { useFormatMessage } from "lang";
import {
  getLiveData,
  getSelectedPowerPlant,
  setShowLiveData,
  useAppDispatch,
  useAppSelector,
} from "store";
import theme from "styles/theme";
import { formatPower } from "utils/energy";

export const CurrentPower = () => {
  const formatMessage = useFormatMessage();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const liveData = useAppSelector(getLiveData);
  const power = useMemo(
    () =>
      formatPower(
        liveData
          ? Object.values(liveData.power).reduce((sum, phase) => sum + phase, 0)
          : 0,
      ),
    [liveData],
  );
  const percentage = useMemo(
    () =>
      selectedPowerPlant?.capacity
        ? ((power.originalValue * 1000) / selectedPowerPlant.capacity) * 100
        : 0,
    [power.originalValue, selectedPowerPlant],
  );

  useEffect(() => {
    const observer = () => {
      const element = ref.current;
      if (!element) return;

      const rect = element.getBoundingClientRect();
      dispatch(setShowLiveData(rect.top <= 0));
    };

    window.addEventListener("scroll", observer);
    return () => window.removeEventListener("scroll", observer);
  }, [dispatch]);

  return (
    <Widget
      ref={ref}
      title={formatMessage("currentPower")}
      icon="bolt"
      content={
        <Grid2 container spacing={2}>
          {selectedPowerPlant?.capacity && (
            <Grid2 container size={5} position={"relative"}>
              <ResponsiveContainer minHeight={56}>
                <PieChart>
                  <Pie
                    dataKey="value"
                    data={[
                      {
                        name: "Power",
                        value: percentage,
                        fill: theme.palette.secondary.main,
                      },
                      {
                        name: "Remaining",
                        value: Math.max(100 - percentage, 0),
                        fill: theme.palette.text.disabled,
                      },
                    ]}
                    startAngle={90}
                    endAngle={-270}
                    innerRadius={"83%"}
                    outerRadius={"115%"}
                    stroke="none"
                  />
                </PieChart>
              </ResponsiveContainer>
              <Typography
                variant="h6"
                align="center"
                color="primary"
                fontSize={12}
                fontWeight={700}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {percentage.toFixed(0)}%
              </Typography>
            </Grid2>
          )}
          <WidgetContent
            value={power.value}
            unit={power.unit}
            gridProps={{ size: 7 }}
          />
        </Grid2>
      }
    />
  );
};
