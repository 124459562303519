import {
  Avatar,
  Button,
  Divider,
  Icon,
  Stack,
  SwipeableDrawer,
  Typography,
} from "@mui/material";

import { useLogout } from "api";
import { useAppLayoutContext } from "components/contexts";
import { useFormatMessage } from "lang";
import { BOTTOM_NAV_HEIGHT } from "routes/consts";
import { getUser, useAppSelector } from "store";
import { isIOS, isPWAiOS } from "utils/detectDevice";

import { SidebarContent } from "./components";
import PWAInfo from "./components/PWAInfo";

export const Sidebar = () => {
  const formatMessage = useFormatMessage();
  const user = useAppSelector(getUser);
  const { sidebarOpen, setSidebarOpen } = useAppLayoutContext();
  const logoutMutation = useLogout();

  const toggleDrawer = (open: boolean) => () => {
    setSidebarOpen(open);
  };

  const handleLogout = async (event: React.MouseEvent) => {
    event.preventDefault();
    await logoutMutation.mutateAsync();
  };

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={sidebarOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={isIOS() && !isPWAiOS()}
        sx={{
          "& .MuiDrawer-paper": {
            height: `calc(100dvh - ${BOTTOM_NAV_HEIGHT}px)`,
            bgcolor: "#1F1F1F",
            backgroundImage: "none",
          },
        }}
      >
        <Stack
          sx={{
            width: 300,
            maxWidth: "70dvw",
            height: "100%",
          }}
        >
          <Stack direction="row" sx={{ px: 4, py: 2, gap: 2 }}>
            <Stack
              direction="row"
              sx={{ gap: 2, alignItems: "center", flexGrow: 1, py: 2 }}
            >
              <Avatar
                sizes="small"
                alt={`${user?.firstName} ${user?.lastName}`}
              >{`${user?.firstName[0]}${user?.lastName[0]}`}</Avatar>
              <Typography component="p" variant="h6">
                {`${user?.firstName} ${user?.lastName}`}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack sx={{ flexGrow: 1 }}>
            <SidebarContent />
            <Divider />
          </Stack>
          <PWAInfo />
          <Stack sx={{ p: 4 }}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<Icon>logout</Icon>}
              onClick={handleLogout}
              sx={{
                textTransform: "none",
              }}
            >
              {formatMessage("logout")}
            </Button>
          </Stack>
        </Stack>
      </SwipeableDrawer>
    </div>
  );
};
