import { BottomNavigation, BottomNavigationAction, Icon } from "@mui/material";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppLayoutContext } from "components/contexts";
import { useFormatMessage } from "lang";
import { BOTTOM_NAV_HEIGHT, DEFAULT } from "routes/consts";
import { isPWAiOS } from "utils/detectDevice";

import { mainListItems } from "./const";

export const BottomNav = () => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { sidebarOpen, setSidebarOpen } = useAppLayoutContext();

  const selectedPathname = useMemo(
    () => `/${pathname.split("/")[1] || ""}`,
    [pathname],
  );
  const otherSelected = useMemo(
    () =>
      sidebarOpen ||
      !mainListItems.map((i) => i.value).includes(selectedPathname),
    [sidebarOpen, selectedPathname],
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    if (newValue === "other") {
      navigate(DEFAULT);
      setSidebarOpen(true);
      return;
    }
    setSidebarOpen(false);
    navigate(newValue);
  };

  return (
    <BottomNavigation
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100000,
        borderTop: "1px solid",
        borderColor: "divider",
        height: `${BOTTOM_NAV_HEIGHT}px`,
        ".MuiBottomNavigationAction-root": {
          pb: isPWAiOS() ? "16px" : 0,
        },
      }}
      value={otherSelected ? "other" : selectedPathname}
      onChange={handleChange}
    >
      {mainListItems.map((item) => (
        <BottomNavigationAction
          key={item.value}
          label={formatMessage(item.label)}
          value={item.value}
          icon={item.icon}
        />
      ))}
      <BottomNavigationAction
        label={formatMessage("other")}
        value={"other"}
        icon={<Icon>more_horiz</Icon>}
      />
    </BottomNavigation>
  );
};
