import { Icon } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import { useFormatMessage } from "lang";
import { isPWA } from "utils/detectDevice";

import { BeforeInstallPromptEvent } from "./types";

export default function PWAInfo() {
  const formatMessage = useFormatMessage();

  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener,
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener,
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        setDeferredPrompt(null);
      });
    }
  };

  if (!isInstallable || isPWA()) return null;

  return (
    <Card variant="outlined" sx={{ m: 3, mb: 0, p: 3, height: "min-content" }}>
      <CardContent>
        <Icon fontSize="small">auto_awesome</Icon>
        <Typography gutterBottom sx={{ fontWeight: 600 }}>
          {formatMessage("pwa.appAvailable")}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: "text.secondary" }}>
          {formatMessage("pwa.info")}
        </Typography>
        <Button
          onClick={handleInstallClick}
          variant="contained"
          size="small"
          fullWidth
        >
          {formatMessage("pwa.getTheApp")}
        </Button>
      </CardContent>
    </Card>
  );
}
