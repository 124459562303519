import { Icon } from "@mui/material";

import { MessageKey } from "lang";
import { DEFAULT, RECORDS, STATISTICS } from "routes/consts";

type ListItem = {
  value: string;
  label: MessageKey;
  icon: JSX.Element;
};

export const mainListItems: ListItem[] = [
  { value: DEFAULT, label: "pageTitle.dashboard", icon: <Icon>home</Icon> },
  { value: RECORDS, label: "pageTitle.records", icon: <Icon>bar_chart</Icon> },
  {
    value: STATISTICS,
    label: "pageTitle.statistics",
    icon: <Icon baseClassName="material-icons-outlined">analytics</Icon>,
  },
];

export const secondaryListItems: ListItem[] = [
  { value: DEFAULT, label: "pageTitle.myAccount", icon: <Icon>person</Icon> },
];
