import { MessageKey } from "lang";

export const validatePassword = (
  password: string,
  setError: (error: MessageKey | undefined) => void,
) => {
  if (!password) {
    setError("error.password.required");
    return false;
  }
  if (password.length < 8) {
    setError("error.password.length");
    return false;
  }
  setError(undefined);
  return true;
};

export const validateConfirmPassword = (
  password: string,
  confirmPassword: string,
  setError: (error: MessageKey | undefined) => void,
): boolean => {
  if (password !== confirmPassword) {
    setError("error.confirmPassword");
    return false;
  }
  setError(undefined);
  return true;
};
