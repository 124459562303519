import { Grid2 } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import {
  addDays,
  addMonths,
  addYears,
  format,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
import { useMemo, useState } from "react";

import { chartDate, HelperFunctionsDatePicker } from "api";
import { useFiltersContext } from "components/contexts";
import { useFormatMessage } from "lang";
import { getSelectedPowerPlant, useAppSelector } from "store";

import { DatePickerChip } from "./components";

export const DatePickerChips = () => {
  const formatMessage = useFormatMessage();
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const {
    date,
    setDate,
    dateType: dateTypeContext,
    setDateType,
  } = useFiltersContext();

  const dateType = useMemo(() => dateTypeContext[0], [dateTypeContext]);

  const powerPlantStartDate = selectedPowerPlant?.startDate
    ? new Date(selectedPowerPlant.startDate)
    : undefined;
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  const helperFunctions: HelperFunctionsDatePicker = {
    day: {
      formatDateType: (date: Date) => format(date, "P"),
      datePickerOptions: {
        views: ["year", "month", "day"],
        hidden: false,
      },
      prevDate: (date: Date) => subDays(date, 1),
      nextDate: (date: Date) => addDays(date, 1),
      prevDisabled: (date: Date) =>
        powerPlantStartDate ? subDays(date, 1) < powerPlantStartDate : false,
      nextDisabled: (date: Date) => addDays(date, 1) > new Date(),
    },
    month: {
      formatDateType: (date: Date) => format(date, "M.y"),
      datePickerOptions: {
        views: ["year", "month"],
        openTo: "month",
        hidden: false,
      },
      prevDate: (date: Date) => subMonths(date, 1),
      nextDate: (date: Date) => addMonths(date, 1),
      prevDisabled: (date: Date) =>
        powerPlantStartDate ? subMonths(date, 1) < powerPlantStartDate : false,
      nextDisabled: (date: Date) => addMonths(date, 1) > new Date(),
    },
    year: {
      formatDateType: (date: Date) => format(date, "y"),
      datePickerOptions: {
        views: ["year"],
        hidden: false,
      },
      prevDate: (date: Date) => subYears(date, 1),
      nextDate: (date: Date) => addYears(date, 1),
      prevDisabled: (date: Date) =>
        powerPlantStartDate ? subYears(date, 1) < powerPlantStartDate : false,
      nextDisabled: (date: Date) => addYears(date, 1) > new Date(),
    },
    total: {
      formatDateType: undefined,
      datePickerOptions: {
        views: ["year"],
        hidden: true,
      },
    },
  };

  return (
    <Grid2
      container
      spacing={1}
      sx={{
        justifyContent: "flex-start",
        flexWrap: "nowrap",
        overflowX: "auto",
        scrollbarGutter: "stable",
      }}
    >
      {chartDate.map((item) => (
        <MobileDatePicker
          key={item}
          views={helperFunctions[dateType].datePickerOptions.views}
          openTo={helperFunctions[dateType].datePickerOptions.openTo}
          value={date[dateType]}
          minDate={powerPlantStartDate}
          maxDate={new Date()}
          open={datePickerOpen}
          onClose={() => setDatePickerOpen(false)}
          onOpen={() => setDatePickerOpen(true)}
          onChange={(newValue: Date | null) =>
            setDate((values) => ({
              ...values,
              [dateType]: newValue,
            }))
          }
          slots={{
            field: DatePickerChip,
          }}
          slotProps={{
            field: {
              zIndex: 1,
              label:
                dateType === item && helperFunctions[dateType].formatDateType
                  ? helperFunctions[dateType].formatDateType(date[dateType])
                  : formatMessage(`grouping.${item}`),
              onClick: () => {
                setDateType([item]);
                if (dateType === item && item !== "total")
                  setDatePickerOpen(true);
              },
              onClickPrev: helperFunctions[item].prevDate
                ? () => {
                    setDate((values) => ({
                      ...values,
                      [dateType]: helperFunctions[item].prevDate!(
                        values[dateType],
                      ),
                    }));
                  }
                : undefined,
              onClickNext: helperFunctions[item].nextDate
                ? () => {
                    setDate((values) => ({
                      ...values,
                      [dateType]: helperFunctions[item].nextDate!(
                        values[dateType],
                      ),
                    }));
                  }
                : undefined,
              prevDisabled: helperFunctions[item].prevDisabled
                ? helperFunctions[item].prevDisabled(date[item])
                : undefined,
              nextDisabled: helperFunctions[item].nextDisabled
                ? helperFunctions[item].nextDisabled(date[item])
                : undefined,
              variant: dateType === item ? "filled" : "outlined",
              hideEditIcon: helperFunctions[item].datePickerOptions.hidden,
            } as {
              label: string;
              onClick: () => void;
              variant: "outlined" | "filled";
            },
          }}
        />
      ))}
    </Grid2>
  );
};
