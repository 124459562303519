import { ChartDateType, EnergyType } from "api";
import { createSafeContext } from "utils/createSafeContext";

type FiltersContextType = {
  date: Record<ChartDateType, Date>;
  setDate: React.Dispatch<React.SetStateAction<Record<ChartDateType, Date>>>;
  dateType: ChartDateType[];
  setDateType: React.Dispatch<React.SetStateAction<ChartDateType[]>>;
  energyType: EnergyType[];
  setEnergyType: React.Dispatch<React.SetStateAction<EnergyType[]>>;
};

export const [FiltersContext, useFiltersContext] =
  createSafeContext<FiltersContextType>("FiltersContext");
