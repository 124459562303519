import { CalculatedEnergy, EnergyType, energyTypeList } from "api";
import { WidgetProps } from "components/elements/mui";
import { EnergyInfoSummary } from "components/elements/widgets";
import { getLiveData, useAppSelector } from "store";

export const EnergyInfoSummaryLiveData = (props: Partial<WidgetProps>) => {
  const liveData = useAppSelector(getLiveData);

  return (
    <EnergyInfoSummary
      energyData={
        Object.fromEntries(
          energyTypeList.map((type) => [
            type,
            liveData ? liveData[type] : undefined,
          ]),
        ) as { [K in EnergyType]: CalculatedEnergy | undefined }
      }
      {...props}
    />
  );
};
