import { useFiltersContext } from "components/contexts";
import { Widget, WidgetContent } from "components/elements/mui";
import { useFormatMessage } from "lang";
import { getLiveData, useAppSelector } from "store";
import { formatEnergy } from "utils/energy";

export const EnergyInfoToday = ({
  icon,
  url,
  size,
}: {
  icon?: string;
  url?: string;
  size?: { xs?: number; sm?: number; md?: number };
}) => {
  const formatMessage = useFormatMessage();
  const liveData = useAppSelector(getLiveData);
  const { energyType: energyTypeContext } = useFiltersContext();
  const energyType = energyTypeContext[0];

  if (!liveData) return null;

  const energy = formatEnergy(liveData[energyType].day);

  return (
    <Widget
      title={`${formatMessage(energyType)} (${formatMessage("today")})`}
      icon={icon}
      content={<WidgetContent value={energy.value} unit={energy.unit} />}
      url={url}
      size={size}
    />
  );
};
