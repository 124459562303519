import { CSSProperties } from "react";

import { createSafeContext } from "utils/createSafeContext";

type AppLayoutContextType = {
  drawerStyles: CSSProperties;
  setDrawerStyles: React.Dispatch<React.SetStateAction<CSSProperties>>;
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const [AppLayoutContext, useAppLayoutContext] =
  createSafeContext<AppLayoutContextType>("AppLayoutContext");
