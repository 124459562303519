import { Grid2, SwipeableDrawer } from "@mui/material";
import { Outlet, useNavigate, useOutlet } from "react-router";

import { useAppLayoutContext } from "components/contexts";
import { DashboardPage } from "routes";
import { BOTTOM_NAV_HEIGHT, DEFAULT } from "routes/consts";

import { BottomNav, Navbar, Sidebar } from "./components";

export const AppLayout = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  const { drawerStyles, setDrawerStyles } = useAppLayoutContext();

  return (
    <Grid2
      container
      direction={"column"}
      bgcolor={"background.default"}
      className="w-screen min-h-dvh h-full"
      sx={{
        scrollbarGutter: "stable",
        paddingTop: "65px",
        paddingBottom: `${BOTTOM_NAV_HEIGHT}px`,
        overflowX: "hidden",
      }}
    >
      <Navbar />
      <Sidebar />

      <DashboardPage />

      <SwipeableDrawer
        anchor="bottom"
        open={!!outlet}
        onOpen={() => {}}
        onClose={() => {
          navigate(DEFAULT);
        }}
        onTransitionEnd={() => {
          if (!outlet) setDrawerStyles({});
        }}
        disableDiscovery
        sx={{
          "& .MuiDrawer-paper": {
            height: "calc(100dvh - 64px)",
            maxHeight: "calc(100dvh - 64px)",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            bgcolor: "#1F1F1F",
            backgroundImage: "none",
            paddingBottom: `${BOTTOM_NAV_HEIGHT}px`,
            ...drawerStyles,
          },
        }}
      >
        <Outlet />
      </SwipeableDrawer>
      <BottomNav />
    </Grid2>
  );
};
