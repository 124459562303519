import { EnergyTypePicker, FiltersWidget } from "components/elements/filters";

export const StatisticsFilters = () => {
  return (
    <FiltersWidget>
      <>
        <EnergyTypePicker />
      </>
    </FiltersWidget>
  );
};
