import { Grid2 } from "@mui/material";

import { FiltersContextProvider } from "components/contexts";
import { EnergyChart } from "components/elements/widgets";
import { PageLayout } from "components/layouts";
import { useFormatMessage } from "lang";

import { RecordsFilters } from "./components";

export const RecordsPage = () => {
  const formatMessage = useFormatMessage();

  return (
    <FiltersContextProvider
      initialState={{
        dateType: ["month"],
      }}
    >
      <PageLayout pageTitle={formatMessage("pageTitle.records")}>
        <Grid2 container spacing={3}>
          <RecordsFilters />
          <EnergyChart showValue />
        </Grid2>
      </PageLayout>
    </FiltersContextProvider>
  );
};
