import { Chip, Grid2 } from "@mui/material";

import { energyTypeList } from "api";
import { useFiltersContext } from "components/contexts";
import { useFormatMessage } from "lang";

export const EnergyTypePicker = ({
  multiselect = false,
}: {
  multiselect?: boolean;
}) => {
  const formatMessage = useFormatMessage();
  const { energyType, setEnergyType } = useFiltersContext();

  return (
    <Grid2
      container
      spacing={1}
      sx={{
        justifyContent: "flex-start",
        flexWrap: "nowrap",
        overflowX: "auto",
        scrollbarGutter: "stable",
      }}
    >
      {energyTypeList.map((item) => {
        const isSelected = energyType.includes(item);

        const onClick = () => {
          if (!multiselect) setEnergyType([item]);
          else if (isSelected)
            setEnergyType(energyType.filter((i) => i !== item));
          else setEnergyType([...energyType, item]);
        };

        return (
          <Chip
            key={item}
            variant={isSelected ? "filled" : "outlined"}
            label={formatMessage(item)}
            onClick={onClick}
          />
        );
      })}
    </Grid2>
  );
};
