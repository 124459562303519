import { LoadingButton } from "@mui/lab";
import { TextField, Typography, Grid2 } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ErrorResponse } from "api";
import { useJoin, useValidateJoinToken } from "api/queries/auth";
import { MessageKey, useFormatMessage } from "lang";
import { LOGIN } from "routes/consts";
import { LoadingPage } from "routes/others";
import { handleErrors } from "utils/errors";
import "./JoinPage.scss";

import { validateConfirmPassword, validatePassword } from "./utils";
import { AppLogo } from "../components";

// TODO - Rewrite this component, use react-hook-form
export const JoinPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formatMessage = useFormatMessage();

  const token = searchParams.get("token");

  const [inviteScreen, setInviteScreen] = useState(true);
  const [inputs, setInputs] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState<{
    general?: MessageKey;
    password?: MessageKey;
    confirmPassword?: MessageKey;
  }>({});

  const {
    data: user,
    isError,
    error: validateTokenError,
  } = useValidateJoinToken({
    data: { token },
    options: { enabled: !!token, retry: false },
  });
  const joinMutation = useJoin();

  useEffect(() => {
    if (isError)
      handleErrors(validateTokenError as AxiosError<ErrorResponse>, setError);
  }, [validateTokenError, isError]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!token) {
      setError((prev) => ({ ...prev, form: "error.invalidToken" }));
      return;
    }
    if (
      !validatePassword(inputs.password, (error) =>
        setError((prev) => ({ ...prev, password: error })),
      ) ||
      !validateConfirmPassword(
        inputs.password,
        inputs.confirmPassword,
        (error) => setError((prev) => ({ ...prev, confirmPassword: error })),
      )
    )
      return;

    try {
      await joinMutation.mutateAsync(
        { token, password: inputs.password },
        {
          onSuccess: () => {
            navigate(LOGIN, { state: { email: user?.email } });
          },
        },
      );
    } catch (error) {
      handleErrors(error as AxiosError<ErrorResponse>, setError);
    }
  };

  if (!user && !error) return <LoadingPage />;

  return (
    <>
      <Grid2
        container
        direction={"column"}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100dvw",
          minHeight: "100dvh",
        }}
      >
        <Grid2
          container
          direction={"column"}
          alignItems={"center"}
          rowGap={3}
          className={`p-6 rounded-md shadow-md screen ${inviteScreen ? "active" : "hidden"}`}
          sx={{ width: "80%", maxWidth: "400px" }}
        >
          {user?.firstName && (
            <Typography
              color="primary"
              sx={{
                fontSize: 36,
                fontWeight: 700,
              }}
              className="animation order1"
            >
              {`${formatMessage("join.hello")}, ${user?.firstName}`}
            </Typography>
          )}
          <Typography
            variant="h5"
            color="primary"
            sx={{
              fontSize: 20,
              fontWeight: 500,
              textTransform: user?.firstName ? "lowercase" : "none",
            }}
            className="animation order2"
          >
            {formatMessage("join.welcomeTo")}
          </Typography>
          <AppLogo className="animation order3" />
          <LoadingButton
            variant="contained"
            fullWidth
            onClick={() => setInviteScreen(false)}
            sx={{
              mt: 10,
            }}
            className="animation order4"
          >
            {formatMessage("join.letsGetStarted")}
          </LoadingButton>
        </Grid2>

        <Grid2
          container
          direction={"column"}
          alignItems={"center"}
          rowGap={3}
          className={`p-6 rounded-md shadow-md screen ${!inviteScreen ? "active" : "hidden"}`}
          sx={{ width: "80%", maxWidth: "400px" }}
        >
          <AppLogo />
          <Typography variant="h6" fontSize={16}>
            {formatMessage("join.setupPassword")}
          </Typography>
          <form onSubmit={handleSubmit} noValidate style={{ width: "100%" }}>
            <Grid2 container direction={"column"} width={"100%"} spacing={6}>
              <TextField
                label={formatMessage("auth.password")}
                name="password"
                type="password"
                variant="outlined"
                fullWidth
                value={inputs.password}
                onChange={handleChange}
                autoComplete="new-password"
                error={!!error.password}
                helperText={error.password && formatMessage(error.password)}
                required
              />
              <TextField
                label={formatMessage("auth.confirmPassword")}
                name="confirmPassword"
                type="password"
                variant="outlined"
                fullWidth
                value={inputs.confirmPassword}
                onChange={handleChange}
                autoComplete="confirm-new-password"
                error={!!error.confirmPassword}
                helperText={
                  error.confirmPassword && formatMessage(error.confirmPassword)
                }
                required
              />
              {error.general && (
                <Typography variant="body2" className="text-red-500">
                  {formatMessage(error.general)}
                </Typography>
              )}
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                disabled={joinMutation.isPending}
                loading={joinMutation.isPending}
              >
                {formatMessage("join.activateAccount")}
              </LoadingButton>
            </Grid2>
          </form>
        </Grid2>
      </Grid2>
    </>
  );
};
