import { tz } from "@date-fns/tz";
import { startOfDay } from "date-fns";
import { useEffect, useMemo, useState } from "react";

export const useToday = (timezoneString: string) => {
  const timezone = useMemo(() => tz(timezoneString), [timezoneString]);

  const [today, setToday] = useState(
    startOfDay(new Date(), {
      in: timezone,
    }),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const now = startOfDay(new Date(), {
        in: timezone,
      });
      if (now.getTime() !== today.getTime()) {
        setToday(now);
      }
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [today, timezone]);

  return today;
};
