import { Grid2 } from "@mui/material";
import { useMemo } from "react";

import { CalculatedEnergy, EnergyType } from "api";
import { useFiltersContext } from "components/contexts";
import { Widget, WidgetContent, WidgetProps } from "components/elements/mui";
import { useFormatMessage } from "lang";
import { formatEnergy } from "utils/energy";

export const EnergyInfoSummary = ({
  energyData,
  ...props
}: Partial<WidgetProps> & {
  energyData: { [K in EnergyType]: CalculatedEnergy | undefined };
}) => {
  const formatMessage = useFormatMessage();
  const { dateType, energyType: energyTypeContext } = useFiltersContext();

  const energyType = useMemo(() => energyTypeContext[0], [energyTypeContext]);

  return (
    <Widget
      title={
        props.title ??
        `${formatMessage("statistics")} (${formatMessage(energyType).toLowerCase()})`
      }
      icon={props.icon ?? "analytics"}
      size={{ xs: 12 }}
      {...props}
      content={
        <Grid2 container spacing={2}>
          {dateType.map((dateType) => {
            if (energyData[energyType]?.[dateType] == null) return null;
            const formattedEnergy = formatEnergy(
              energyData[energyType][dateType],
            );

            return (
              <Widget
                key={dateType}
                title={formatMessage(`widget.this.${dateType}`)}
                size={{
                  xs: 6,
                }}
                content={
                  <WidgetContent
                    value={formattedEnergy.value}
                    unit={formattedEnergy.unit}
                  />
                }
              />
            );
          })}
        </Grid2>
      }
    />
  );
};
