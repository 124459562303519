import { LoadingButton } from "@mui/lab";
import { TextField, Typography, Grid2 } from "@mui/material";
import { AxiosError } from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import "./LoginPage.scss";

import { ErrorResponse } from "api";
import { useLogin } from "api/queries/auth"; // Adjust this import path to match your project
import { MessageKey, useFormatMessage } from "lang";
import { handleUserResponse, setTokens, useAppDispatch } from "store";
import { handleErrors } from "utils/errors";

import { validateEmail, validatePassword } from "./utils";
import { AppLogo } from "../components";

// TODO - Rewrite this component, use react-hook-form
export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const formatMessage = useFormatMessage();
  const location = useLocation();

  const [inputs, setInputs] = useState<{
    email: string;
    password: string;
  }>({
    email: location.state?.email || "",
    password: "",
  });
  const [error, setError] = useState<{
    general?: MessageKey;
    email?: MessageKey;
    password?: MessageKey;
  }>({});

  const loginMutation = useLogin();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError({});

    if (
      !validateEmail(inputs.email, (error) =>
        setError((prev) => ({ ...prev, email: error })),
      ) ||
      !validatePassword(inputs.password, (error) =>
        setError((prev) => ({ ...prev, password: error })),
      )
    )
      return;

    try {
      await loginMutation.mutateAsync(
        { email: inputs.email, password: inputs.password },
        {
          onSuccess: (data) => {
            const { accessToken, refreshToken, ...rest } = data.data;
            dispatch(setTokens({ accessToken, refreshToken }));
            dispatch(handleUserResponse(rest));
          },
        },
      );
    } catch (error) {
      handleErrors(error as AxiosError<ErrorResponse>, setError);
    }
  };

  return (
    <>
      <Grid2
        container
        direction={"column"}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100dvw",
          minHeight: "100dvh",
        }}
      >
        {/* <Icon className="sun">light_mode</Icon> */}
        <Grid2
          container
          direction={"column"}
          alignItems={"center"}
          rowGap={3}
          className="p-6 rounded-md shadow-md"
          sx={{ width: "80%", maxWidth: "400px" }}
        >
          <AppLogo />
          <Typography variant="h6" fontSize={16}>
            {formatMessage("login.logIntoYourAccount")}
          </Typography>
          <form onSubmit={handleSubmit} noValidate style={{ width: "100%" }}>
            <Grid2 container direction={"column"} width={"100%"} spacing={6}>
              <TextField
                label="Email"
                name="email"
                variant="outlined"
                fullWidth
                value={inputs.email}
                onChange={handleChange}
                required
                autoComplete="email"
                error={!!error.email}
                helperText={error.email && formatMessage(error.email)}
              />
              <TextField
                label={formatMessage("auth.password")}
                name="password"
                type="password"
                variant="outlined"
                fullWidth
                value={inputs.password}
                onChange={handleChange}
                required
                autoComplete="current-password"
                error={!!error.password}
                helperText={error.password && formatMessage(error.password)}
              />
              {error.general && (
                <Typography variant="body2" className="text-red-500">
                  {formatMessage(error.general)}
                </Typography>
              )}
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                disabled={loginMutation.isPending}
                loading={loginMutation.isPending}
              >
                {formatMessage("login.login")}
              </LoadingButton>
            </Grid2>
          </form>
        </Grid2>
      </Grid2>
    </>
  );
};
