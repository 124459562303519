import { grey, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  cssVariables: true,
  spacing: 4,
  palette: {
    mode: "dark",
    primary: {
      main: grey[100],
    },
    secondary: {
      main: "#0ea4a2",
    },
    tertiary: {
      main: grey[500],
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#000000",
    },
    action: {
      hover: grey[800],
    },
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: "100%",
          backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#fff",
          color: theme.palette.mode === "dark" ? "#fff" : "#000",
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 8,
          ":last-child": {
            paddingBottom: 8,
          },
        },
      },
    },
  },
});

export default theme;
