import { Grid2 } from "@mui/material";

import { Widget } from "components/elements/mui";
import { useFormatMessage } from "lang";

export const FiltersWidget = ({
  expandable = true,
  expandedByDefault = true,
  children,
}: React.PropsWithChildren<{
  expandable?: boolean;
  expandedByDefault?: boolean;
}>) => {
  const formatMessage = useFormatMessage();

  return (
    <Widget
      title={formatMessage("filters")}
      icon="tune"
      size={{ xs: 12 }}
      expandable={expandable}
      onlyHeaderClickable
      expandedByDefault={expandedByDefault}
      content={
        <Grid2 container spacing={2} direction={"column"} flexWrap={"nowrap"}>
          {children}
        </Grid2>
      }
    />
  );
};
