import { AxiosError } from "axios";

import { ErrorResponse } from "api";
import { MessageKey } from "lang";

export const handleErrors = (
  error: AxiosError<ErrorResponse>,
  setError: React.Dispatch<React.SetStateAction<Record<string, MessageKey>>>,
) => {
  if (Array.isArray(error?.response?.data)) {
    error.response.data.forEach((validationError) => {
      setError((prev) => ({
        ...prev,
        [validationError.field]: validationError.message,
      }));
    });
  } else if (error?.response?.data?.message) {
    setError((prev) => ({
      ...prev,
      general: (error?.response?.data as { message: MessageKey })?.message,
    }));
  }
};
