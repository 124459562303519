import { Box, Grid2, Grid2Props, Typography } from "@mui/material";

export const AppLogo = (props: Grid2Props) => (
  <Grid2 container direction={"column"} alignItems={"center"} {...props}>
    <Box
      component="img"
      src="/icons/pwa-512x512.png"
      alt="Solar logo"
      sx={{
        width: "80px",
        height: "auto",
        borderRadius: 2,
      }}
    />
    <Typography
      color="primary"
      sx={{
        fontSize: 48,
        fontWeight: 900,
        lineHeight: 1.2,
      }}
    >
      Solar
    </Typography>
  </Grid2>
);
