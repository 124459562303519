import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { JoinPage, LoadingPage } from "routes";

import { LoginPage } from "./auth/login";
import { ANY_OTHER, JOIN, LOGIN } from "./consts";

export const PublicRoutes = () => (
  <Suspense fallback={<LoadingPage />}>
    <Routes>
      <Route path={LOGIN} element={<LoginPage />} />
      <Route path={JOIN} element={<JoinPage />} />

      <Route path={ANY_OTHER} element={<Navigate to={LOGIN} replace />} />
    </Routes>
  </Suspense>
);
