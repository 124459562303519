import {
  DatePickerChips,
  EnergyTypePicker,
  FiltersWidget,
} from "components/elements/filters";

export const RecordsFilters = () => {
  return (
    <FiltersWidget>
      <>
        <DatePickerChips />
        <EnergyTypePicker />
      </>
    </FiltersWidget>
  );
};
