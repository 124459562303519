import { Divider, Grid2, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useMemo } from "react";

import { getLiveData, getShowLiveData, useAppSelector } from "store";
import { formatEnergy, formatPower } from "utils/energy";

export const LiveData = ({ show: providedShow }: { show?: boolean }) => {
  const liveData = useAppSelector(getLiveData);
  const showLiveData = useAppSelector(getShowLiveData);
  const show = providedShow || showLiveData;

  const energyProduced = formatEnergy(
    liveData ? liveData.energyProduced.day : 0,
  );
  const power = useMemo(
    () =>
      formatPower(
        liveData
          ? Object.values(liveData.power).reduce((sum, phase) => sum + phase, 0)
          : 0,
      ),
    [liveData],
  );
  if (!liveData || !show) return null;

  return (
    <Grid2
      container
      size={12}
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid2
        container
        justifyContent="flex-end"
        alignItems={"flex-end"}
        spacing={1}
        size={5}
      >
        <Typography fontSize={15} fontWeight={700}>
          {power.value}
        </Typography>
        <Typography color={grey[700]} fontSize={15} fontWeight={700}>
          {power.unit}
        </Typography>
      </Grid2>
      <Divider orientation="vertical" flexItem />
      <Grid2 container alignItems={"flex-end"} spacing={1} size={5}>
        <Typography fontSize={15} fontWeight={700}>
          {energyProduced.value}
        </Typography>
        <Typography color={grey[700]} fontSize={15} fontWeight={700}>
          {energyProduced.unit}
        </Typography>
      </Grid2>
    </Grid2>
  );
};
