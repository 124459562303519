import { isPWAiOS } from "utils/detectDevice";

export const DEFAULT = "/";
export const ANY_OTHER = "*";

export const LOGIN = "/login";
export const JOIN = "/join";

export const RECORDS = "/records";
export const STATISTICS = "/statistics";

export const BOTTOM_NAV_HEIGHT = isPWAiOS() ? 84 : 56;
