import { Chip, Icon } from "@mui/material";

export const DatePickerChip = ({
  label,
  onClick,
  onClickPrev,
  onClickNext,
  prevDisabled,
  nextDisabled,
  variant,
  hideEditIcon,
}: {
  label?: string;
  onClick?: () => void;
  onClickPrev?: () => void;
  onClickNext?: () => void;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  variant?: "outlined" | "filled";
  hideEditIcon?: boolean;
}) => {
  const arrowSX = {
    ".MuiChip-label": { display: "flex", px: "5px" },
    borderRadius: "14px",
  };
  return (
    <>
      {variant === "filled" && onClickPrev && (
        <Chip
          variant="outlined"
          disabled={prevDisabled}
          onClick={onClickPrev}
          label={
            <Icon fontSize="small" baseClassName="material-icons-outlined">
              navigate_before
            </Icon>
          }
          sx={arrowSX}
        />
      )}
      <Chip
        variant={variant}
        label={label}
        onClick={onClick}
        onDelete={variant === "filled" && !hideEditIcon ? onClick : undefined}
        deleteIcon={
          variant === "filled" && !hideEditIcon ? (
            <Icon fontSize="small" baseClassName="material-icons-outlined">
              edit_calendar
            </Icon>
          ) : undefined
        }
      />
      {variant === "filled" && onClickNext && (
        <Chip
          variant="outlined"
          disabled={nextDisabled}
          onClick={onClickNext}
          label={
            <Icon fontSize="small" baseClassName="material-icons-outlined">
              navigate_next
            </Icon>
          }
          sx={arrowSX}
        />
      )}
    </>
  );
};
