import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from "virtual:pwa-register/react";

import { ApiProvider } from "api";
import { LocaleProvider } from "lang";
import { Routes } from "routes";
import { store } from "store";
import theme from "styles/theme";

export const App: React.FC = () => {
  useRegisterSW({
    immediate: true,
    onNeedRefresh() {
      window.location.reload();
    },
    onOfflineReady() {
      console.log("App is ready to work offline.");
    },
  });

  return (
    <ApiProvider>
      <LocaleProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </LocaleProvider>
    </ApiProvider>
  );
};
