import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSetupAxios } from "api";
import { AppLayoutContextProvider } from "components/contexts";
import { LoadingPage } from "routes";
import { getTokens, getUser, useAppSelector } from "store";

import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { useSetupApp } from "./utils";

export const Routes: React.FC = () => {
  const navigate = useNavigate();
  const tokens = useAppSelector(getTokens);
  const user = useAppSelector(getUser);

  useSetupAxios();
  const appLoaded = useSetupApp();

  const [newVersion, setNewVersion] = useState(false);

  useEffect(() => {
    const handleNewVersion = (event: Event) => {
      event.preventDefault();
      setNewVersion(true);
      navigate(0);
    };
    window.addEventListener("vite:preloadError", handleNewVersion);
    return () => {
      window.removeEventListener("vite:preloadError", handleNewVersion);
    };
  }, [navigate]);

  if (!appLoaded || (tokens && !user) || newVersion) {
    return <LoadingPage />;
  }

  if (!user) {
    return <PublicRoutes />;
  }

  return (
    <AppLayoutContextProvider>
      <PrivateRoutes />
    </AppLayoutContextProvider>
  );
};
