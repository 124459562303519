import { CSSProperties, useState } from "react";

import { AppLayoutContext } from "./AppLayoutContext";

export const AppLayoutContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [drawerStyles, setDrawerStyles] = useState<CSSProperties>({});
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <AppLayoutContext.Provider
      value={{
        drawerStyles,
        setDrawerStyles,
        sidebarOpen,
        setSidebarOpen,
      }}
    >
      {children}
    </AppLayoutContext.Provider>
  );
};
