import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import {
  AUTH,
  axiosInstance,
  ErrorResponse,
  JoinRequest,
  JoinResponse,
  LoginRequest,
  LoginResponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
  ValidateJoinTokenRequest,
  ValidateJoinTokenResponse,
} from "api";
import { logoutAction } from "utils/logout";

export const useJoin = () => {
  return useMutation<
    AxiosResponse<JoinResponse>,
    AxiosError<ErrorResponse>,
    JoinRequest
  >({
    mutationFn: (data: JoinRequest) =>
      axiosInstance.post<JoinResponse>(AUTH.JOIN, data),
  });
};

export const useLogin = () => {
  const mutation = useMutation<
    AxiosResponse<LoginResponse>,
    AxiosError,
    LoginRequest
  >({
    mutationFn: (data: LoginRequest) =>
      axiosInstance.post<LoginResponse>(AUTH.LOGIN, data),
  });
  return mutation;
};

export const useLogout = () => {
  return useMutation<AxiosResponse<LoginResponse>, AxiosError>({
    mutationFn: () => axiosInstance.post<LoginResponse>(AUTH.LOGOUT),
    onSuccess: () => {
      logoutAction();
    },
  });
};

export const useRefreshToken = () => {
  return useMutation<
    AxiosResponse<RefreshTokenResponse>,
    AxiosError,
    RefreshTokenRequest
  >({
    mutationFn: (data: RefreshTokenRequest) =>
      axiosInstance.post<RefreshTokenResponse>(AUTH.REFRESH_TOKEN, data),
  });
};

export const useValidateJoinToken = ({
  data: { token },
  options,
}: {
  data: ValidateJoinTokenRequest;
  options?: Partial<UseQueryOptions<ValidateJoinTokenResponse>>;
}) => {
  return useQuery<ValidateJoinTokenResponse>({
    queryKey: [AUTH.VALIDATE_JOIN_TOKEN, token],
    queryFn: async () => {
      const data = await axiosInstance.post<ValidateJoinTokenResponse>(
        AUTH.VALIDATE_JOIN_TOKEN,
        { token },
      );
      return data.data;
    },
    enabled: !!token,
    ...options,
  });
};
