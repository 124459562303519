import { useState } from "react";

import { ChartDateType, EnergyType } from "api";

import { FiltersContext } from "./FiltersContext";

export const FiltersContextProvider: React.FC<{
  initialState?: {
    date?: Record<ChartDateType, Date>;
    dateType?: ChartDateType[];
    energyType?: EnergyType[];
  };
  children: React.ReactNode;
}> = ({ initialState, children }) => {
  const [date, setDate] = useState<Record<ChartDateType, Date>>(
    initialState?.date ?? {
      day: new Date(),
      month: new Date(),
      year: new Date(),
      total: new Date(),
    },
  );
  const [dateType, setDateType] = useState<ChartDateType[]>(
    initialState?.dateType ?? ["day"],
  );
  const [energyType, setEnergyType] = useState<EnergyType[]>(
    initialState?.energyType ?? ["energyProduced"],
  );

  return (
    <FiltersContext.Provider
      value={{
        date,
        setDate,
        dateType,
        setDateType,
        energyType,
        setEnergyType,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
