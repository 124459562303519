import { differenceInMilliseconds } from "date-fns";
import { useEffect, useState } from "react";

import { Tokens, useGetPowerPlantLive, useGetUser } from "api";
import {
  getSelectedPowerPlant,
  getTokens,
  getUser,
  handleUserResponse,
  setLiveData,
  setTokens,
  useAppDispatch,
  useAppSelector,
} from "store";
import { TOKENS_LOCAL_STORAGE_KEY } from "store/consts";
import { getFromLocalStorage } from "utils/localStorage";

export const useSetupApp = (): boolean => {
  const dispatch = useAppDispatch();
  const tokens = useAppSelector(getTokens);
  const user = useAppSelector(getUser);
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);

  const [appLoaded, setAppLoaded] = useState(false);

  const { data: userData } = useGetUser({
    enabled: !!tokens?.accessToken && !user?.email,
  });

  const {
    data: liveData,
    isError: liveDataError,
    isRefetching,
  } = useGetPowerPlantLive({
    options: {
      refetchInterval: selectedPowerPlant?.liveDataInterval,
      refetchOnWindowFocus: true,
      staleTime: selectedPowerPlant?.liveDataInterval,
    },
  });

  // Load tokens from local storage only on first render
  useEffect(() => {
    const tokensLocalStorage = getFromLocalStorage<Tokens>(
      TOKENS_LOCAL_STORAGE_KEY,
    );
    if (tokensLocalStorage) {
      dispatch(setTokens(tokensLocalStorage));
    }
    setAppLoaded(true);
  }, [dispatch, setAppLoaded]);

  // Handle user response when user is not in store
  useEffect(() => {
    if (userData) {
      dispatch(handleUserResponse(userData));
    }
  }, [userData, dispatch]);

  // Handle live data response
  useEffect(() => {
    if (liveData && !isRefetching) {
      dispatch(
        setLiveData({
          ...liveData,
          offline:
            liveDataError ||
            differenceInMilliseconds(new Date(), liveData.liveData.date) >
              4 * (selectedPowerPlant?.liveDataInterval || 15000),
        }),
      );
    }
  }, [
    liveData,
    liveDataError,
    dispatch,
    isRefetching,
    selectedPowerPlant?.liveDataInterval,
  ]);

  return appLoaded;
};
