import { Box, CircularProgress, Grid2 } from "@mui/material";

import { FiltersContextProvider } from "components/contexts";
import {
  AdditionalReadings,
  CurrentPower,
  EnergyChart,
  EnergyInfoSummaryLiveData,
  EnergyInfoToday,
} from "components/elements/widgets";
import { RECORDS, STATISTICS } from "routes/consts";
import { getLiveData, useAppSelector } from "store";

export const DashboardPage = () => {
  const liveData = useAppSelector(getLiveData);

  if (!liveData)
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Grid2 container spacing={2} p={2} width={"100%"}>
      <CurrentPower />
      <FiltersContextProvider>
        <EnergyInfoToday icon="solar_power" url={STATISTICS} />
        <EnergyChart url={RECORDS} showToday />
      </FiltersContextProvider>
      <FiltersContextProvider initialState={{ dateType: ["month", "year"] }}>
        <EnergyInfoSummaryLiveData url={STATISTICS} />
      </FiltersContextProvider>
      <AdditionalReadings />
    </Grid2>
  );
};
