import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { axiosInstance, LiveDataResponse, POWER_PLANT } from "api";
import { getSelectedPowerPlant, useAppSelector } from "store";

export const useGetPowerPlantLive = ({
  meterId: meterIdProp,
  options,
}: {
  meterId?: string;
  options?: Partial<UseQueryOptions<LiveDataResponse>>;
}) => {
  const selectedPowerPlant = useAppSelector(getSelectedPowerPlant);
  const meterId = meterIdProp || selectedPowerPlant?.meterId;

  return useQuery<LiveDataResponse>({
    queryKey: [POWER_PLANT.POWER_PLANT_LIVE(meterId!)],
    queryFn: async () => {
      const data = await axiosInstance.get<LiveDataResponse>(
        POWER_PLANT.POWER_PLANT_LIVE(meterId!),
      );
      return data.data;
    },
    enabled: !!meterId,
    ...options,
  });
};
