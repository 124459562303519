import { Grid2 } from "@mui/material";

import { FiltersContextProvider } from "components/contexts";
import { EnergyInfoSummaryLiveData } from "components/elements/widgets";
import { PageLayout } from "components/layouts";
import { useFormatMessage } from "lang";

import { StatisticsFilters } from "./components";

export const StatisticsPage = () => {
  const formatMessage = useFormatMessage();

  // Issues with statering. Maybe should apply those styles before navigation
  // useEffect(() => {
  //   setDrawerStyles({ height: "450px" });
  // }, [setDrawerStyles]);

  return (
    <FiltersContextProvider
      initialState={{ dateType: ["day", "month", "year", "total"] }}
    >
      <PageLayout pageTitle={formatMessage("pageTitle.statistics")}>
        <Grid2 container spacing={3}>
          <StatisticsFilters />
          <EnergyInfoSummaryLiveData icon="analytics" />
        </Grid2>
      </PageLayout>
    </FiltersContextProvider>
  );
};
